import React from 'react';

let langData = {
    "CanadianEnglish": "enCA",
    "SimplifiedChinese": "zhSimplified",
    "TraditionalChinese": "zhTraditional",
};


export let MainWrapper = React.createClass({
    getInitialState: function() {
        return {
            "contentName": "home",
            "selectLang": true,
            "lang": langData["CanadianEnglish"],
        }
    },
    handleNavBarTabClick: function(tabName) {
        this.setState({
            "contentName": tabName
        });
    },
    openLangSelectModal: function() {
        this.setState({
            "selectLang": true
        });
    },
    handleLangChanged: function(langId) {
        let s = document.getElementById("dynamicDataScript");
        s.parentNode.removeChild(s);
        let dataScript = document.createElement("script");
        dataScript.id = "dynamicDataScript";
        dataScript.type = "text/javascript";
        if (langId == langData["SimplifiedChinese"]) {
            dataScript.src = "/static/js/data_zhSimplified.js";
        } else if (langId == langData["TraditionalChinese"]) {
            dataScript.src = "/static/js/data_zhTraditional.js";
        } else {
            dataScript.src = "/static/js/data.js";
        }
        document.getElementsByTagName("body")[0].insertBefore(dataScript, document.getElementById("mainContent"));
        this.setState({
            "selectLang": false,
            "lang": langId
        });
        setTimeout(function(){
            this.forceUpdate();
        }.bind(this), 500);
    },
    handleLangUnchanged: function() {
        this.setState({
            "selectLang": false
        });
    },
    render: function() {
        return (
            <div>
                <LangModal showModal={this.state.selectLang} lang={this.state.lang} onLangChanged={this.handleLangChanged} onLangUnchanged={this.handleLangUnchanged} />
                <NavBarWrapper opNavBarTabClick={this.handleNavBarTabClick} onLangCtrlClick={this.openLangSelectModal} />
                <ContentWrapper contentName={this.state.contentName} />
                <FooterWrapper />
            </div>
        );
    }
});


let LangModal = React.createClass({
    componentDidMount: function() {
        jQuery("#langCtrlModal").modal({backdrop: 'static', keyboard: false}).modal("show");
    },
    componentDidUpdate: function() {
        this.componentDidMount();
    },
    handleLangSelected: function(langId) {
        jQuery("#langCtrlModal").modal("hide");
        if (this.props.lang != langId) {
            this.props.onLangChanged(langId);
        } else {
            this.props.onLangUnchanged();
        }
    },
    render: function() {
        if (this.props.showModal) {
            return (
                <div id="langCtrlModal" className="modal fade" tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title" style={{"color": "#265a88"}}>Choose Your Language</h4>
                            </div>
                            <LangModalBody onModalBodyLangSeleted={this.handleLangSelected} />
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div />
            );
        }
    }
});


let LangModalBody = React.createClass({
    handleSelectLang: function(e) {
        this.props.onModalBodyLangSeleted(e.target.id);
    },
    render: function() {
        return (
            <div className="modal-body">
                <div className="selectLang">
                    <div id={langData["CanadianEnglish"]} className="langButton" onClick={this.handleSelectLang}>English</div>
                    <div id={langData["SimplifiedChinese"]} className="langButton" onClick={this.handleSelectLang}>简体中文</div>
                    <div id={langData["TraditionalChinese"]} className="langButton" onClick={this.handleSelectLang}>繁体中文</div>
                </div>
            </div>
        );
    }
});


let NavBarWrapper = React.createClass({
    rawMarkup: simpleMarkup,
    getInitialState: function() {
        return {
            'activeTab': 'home'
        };
    },
    handleBrandClick: function() {
        document.getElementById("homeTab").click();
    },
    handleNavTabClick: function(tabName) {
        this.setState({
            'activeTab': tabName
        });
        this.props.opNavBarTabClick(tabName);
    },
    handleLangCtrlClick: function() {
        this.props.onLangCtrlClick();
    },
    setClass: function(className) {
        if (this.state.activeTab == className) {
            return 'active';
        } else {
            return '';
        }
    },
    render: function() {
        return (
            <div>
                <nav className="navbar navbar-cos">
                    <div className="container">
                        <div className="navbar-header">
                            <button ref="toggleButton" type="button" className="navbar-toggle" data-toggle="collapse" href="#navigationbar">
                                <span className="sr-only">Toggle navigation</span>
                                <span className="icon-bar" />
                                <span className="icon-bar" />
                                <span className="icon-bar" />
                            </button>
                            <a
                                className="brand navbar-brand"
                                onClick={this.handleBrandClick}
                                dangerouslySetInnerHTML={this.rawMarkup(navBarData["navBarBrand"])}
                            />
                        </div>
                        <div id="navigationbar" className="collapse navbar-collapse navbar-right">
                            <ul className="nav navbar-nav">
                                <NavBarTab className={this.setClass('home')} data={"home"} name={navBarData["homeTabName"]} onNavTabClick={this.handleNavTabClick} />
                                <NavBarTab className={this.setClass('gallery')} data={"gallery"} name={navBarData["galleryTabName"]} onNavTabClick={this.handleNavTabClick} />
                                <NavBarTab className={this.setClass('services')} data={"services"} name={navBarData["servicesTabName"]} onNavTabClick={this.handleNavTabClick} />
                                <NavBarTab className={this.setClass('about')} data={"about"} name={navBarData["aboutTabName"]} onNavTabClick={this.handleNavTabClick} />
                                <NavBarTab className={this.setClass('contact')} data={"contact"} name={navBarData["contactTabName"]} onNavTabClick={this.handleNavTabClick} />
                                <li onClick={this.handleLangCtrlClick}><a id="langCtrl">{navBarData["lang"]}<span className="caret" /></a></li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        );
    }
});


let NavBarTab = React.createClass({
    rawMarkup: simpleMarkup,
    handleClick: function() {
        this.props.onNavTabClick(this.props.data);
    },
    render: function() {
        return (
            <li className={this.props.className}>
                <a
                    id={this.props.data + "Tab"}
                    dangerouslySetInnerHTML={this.rawMarkup(capFirst(this.props.name))}
                    onClick={this.handleClick}
                />
            </li>
        );
    }
});


let ContentWrapper = React.createClass({
    render: function() {
        let SubWrapper;
        switch (this.props.contentName) {
            case "home":
                SubWrapper = <HomeWrapper />;
                break;
            case "gallery":
                SubWrapper = <GalleryWrapper />;
                break;
            case "services":
                SubWrapper = <ServicesWrapper />;
                break;
            case "about":
                SubWrapper = <AboutWrapper />;
                break;
            case "contact":
                SubWrapper = <ContactWrapper />;
                break;
            default:
                SubWrapper = <HomeWrapper />;
                break;
        }
        return (
            <div className="mainContent">
                {SubWrapper}
            </div>
        );
    }
});


let HomeWrapper = React.createClass({
    render: function() {
        return (
            <div id="homeWrapper">
                <CaroselWrapper />
                <IntroWrapper />
            </div>
        );
    }
});


let CaroselWrapper = React.createClass({
    render: function() {
        return (
            <div className="jumbotron titleWithImageBackground-home" role="main">
                <div className="container titleWithImageBackground-text" />
            </div>
        );
    }
});


let IntroWrapper = React.createClass({
    render: function() {
        return (
            <div id="IntroWrapper" className="container marketing">
                <IntroSection moreId={"moreServices"} data={introData["section1"]} />
                <hr className="featurette-divider" />
                <IntroSection moreId={"moreGallery"} data={introData["section2"]} pullRight={true} />
                <hr className="featurette-divider" />
                <IntroSection moreId={"moreAbout"} data={introData["section3"]} />
            </div>
        );
    }
});


let IntroSection = React.createClass({
    rawMarkup: simpleMarkup,
    handleMoreClick: function(e) {
        e.preventDefault();
        window.scrollTo(0, 0);
        document.getElementById(e.target.id.substr(4).toLowerCase() + "Tab").click();
    },
    setClass: function() {
        if (this.props.pullRight) {
            return {
                "textClass": "col-md-7 col-md-push-5",
                "imgClass": "col-md-5 col-md-pull-7",
            };
        } else {
            return {
                "textClass": "col-md-7",
                "imgClass": "col-md-5",
            };
        }
    },
    render: function() {
        return (
            <div className="row featurette">
                <a name="professionalInteriorRenovation" />
                <div className={this.setClass()["textClass"]}>
                    <h2 className="featurette-heading" dangerouslySetInnerHTML={this.rawMarkup(this.props.data.head)} />
                    <div className="lead" dangerouslySetInnerHTML={this.rawMarkup(this.props.data.shortIntro)} />
                    <ul className="lead" dangerouslySetInnerHTML={this.rawMarkup(this.props.data.content)} />
                    <a
                        id={this.props.moreId}
                        className="services-intro-text-link"
                        href="javascript:"
                        onClick={this.handleMoreClick}
                    >{constData["readMore"]}</a>
                </div>
                <div className={this.setClass()["imgClass"]}>
                    <img height="500" width="500" className="featurette-image img-responsive img-thumbnail center-block" src={this.props.data.imgPath} data-holder-rendered="true" />
                </div>
            </div>
        );
    }
});


let GalleryWrapper = React.createClass({
    getInitialState: function() {
        return {
            "activeGalleryItem": "All",
        }
    },
    handleGalleryContentCtrlClick: function(galleryItem) {
        this.setState({
            "activeGalleryItem": galleryItem
        });
    },
    render: function() {
        let GalleryContent;
        let activeGalleryItem = this.state.activeGalleryItem;
        let galleryContentCtrlClickHandler = this.handleGalleryContentCtrlClick;
        let GalleryContentCtrlList = galleryClassification.map(function(data) {
            return (
                <GalleryContentCtrl
                    key={data.id}
                    galleryItem={data.item}
                    galleryName={data.name}
                    activeGalleryItem={activeGalleryItem}
                    onGalleryContentCtrlClick={galleryContentCtrlClickHandler}
                />
            );
        });
        if (this.state.activeGalleryItem == "All") {
            GalleryContent = <GalleryContentWrapper
                onGalleryContentCtrlClick={galleryContentCtrlClickHandler}
            />
        } else {
            GalleryContent = <GalleryContentDetail data={galleryItemDetailData[capFirst(this.state.activeGalleryItem)]} />
        }
        return (
            <div id="GalleryWrapper">
                <div className="jumbotron titleWithImageBackground-gallery" role="main">
                    <div className="container titleWithImageBackground-text">
                        <h1>{galleryData["title"]}</h1>
                        <p>{galleryData["shortIntro"]}</p>
                    </div>
                </div>
                <div className="container">
                    <div className="two-col-left col-md-3">
                        <ul className="list-group">
                            <GalleryContentCtrl
                                galleryItem={"All"}
                                galleryName={constData["all"]}
                                activeGalleryItem={activeGalleryItem}
                                onGalleryContentCtrlClick={galleryContentCtrlClickHandler} />
                            {GalleryContentCtrlList}
                        </ul>
                    </div>
                    <div className="two-col-right col-md-9">
                        {GalleryContent}
                    </div>
                </div>
            </div>
        );
    }
});


let GalleryContentWrapper = React.createClass({
    handleGalleryPreviewMoreButtonClick: function(item) {
        this.props.onGalleryContentCtrlClick(item);
    },
    render: function() {
        let galleryPreviewMoreButtonClickHandler = this.handleGalleryPreviewMoreButtonClick;
        let GalleryInfo = galleryClassification.map(function(data) {
            return (
                <GalleryContentPreviewWrapper
                    key={data.id}
                    item={data.item}
                    name={data.name}
                    onGalleryMoreButtonClick={galleryPreviewMoreButtonClickHandler}
                />
            );
        });
        return (
            <div className="gallery-thumbnails">
                {GalleryInfo}
            </div>
        );
    }
});


let GalleryContentDetail = React.createClass({
    rawMarkup: simpleMarkup,
    render: function() {
        let GalleryThumbnailList = [];
        for (let i = 0; i <= this.props.data.len; ++i) {
            GalleryThumbnailList.push(
                <GalleryThumbnail key={i} data={this.props.data.item} imgNum={i.toString()} />
            );
        }
        return (
            <div className="row galleryClassificationWrapper">
                <div className="galleryClassificationInfo col-md-12">
                    <span
                        className="galleryClassificationTitle pull-left"
                        dangerouslySetInnerHTML={this.rawMarkup(capFirst(this.props.data.name))}
                    />
                </div>
                <div className="gallery-thumbnails">
                    {GalleryThumbnailList}
                </div>
            </div>
        );
    }
});


let GalleryContentCtrl = React.createClass({
    rawMarkup: simpleMarkup,
    handleClick: function() {
        this.props.onGalleryContentCtrlClick(this.props.galleryItem);
    },
    setClass: function() {
        if (this.props.activeGalleryItem == this.props.galleryItem) {
            return "list-group-item active";
        } else {
            return "list-group-item";
        }
    },
    render: function() {
        return (
            <li className={this.setClass()} onClick={this.handleClick}>
                <a
                    href="javascript:"
                    dangerouslySetInnerHTML={this.rawMarkup(capFirst(this.props.galleryName))}
                />
            </li>
        );
    }
});


let GalleryContentPreviewWrapper = React.createClass({
    rawMarkup: simpleMarkup,
    handleMoreButtonClick: function() {
        this.props.onGalleryMoreButtonClick(this.props.item);
    },
    render: function() {
        return (
            <div className="row galleryClassificationWrapper">
                <div className="galleryClassificationInfo col-md-12">
                    <span
                        className="galleryClassificationTitle pull-left"
                        dangerouslySetInnerHTML={this.rawMarkup(capFirst(this.props.name))}
                    />
                    <span
                        className="galleryClassificationMore pull-right"
                        onClick={this.handleMoreButtonClick}
                    >{constData["more"]}</span>
                </div>
                <GalleryThumbnail data={this.props.item} imgNum={"1"} />
                <GalleryThumbnail data={this.props.item} imgNum={"2"} />
                <GalleryThumbnail data={this.props.item} imgNum={"3"} />
            </div>
        );
    }
});


let GalleryThumbnail = React.createClass({
    handleThumbnailClick: function() {
        jQuery("#imgFullViewModal").modal("show").on("hidden.bs.modal", function() {
            jQuery("#imgFullViewWrapper").html("");
        });
        jQuery("#imgFullViewWrapper").html(
            '<img class="center-block" src="/static/images/gallery/' + this.props.data + '/' + this.props.data +
            this.props.imgNum + '.jpg" style="width: 100%; height: auto" />'
        );
    },
    render: function() {
        return (
            <div className="col-md-4 gallery-thumbnail-wrapper" onClick={this.handleThumbnailClick}>
                <img
                    className="img-thumbnail center-block"
                    src={"/static/images/gallery/" + this.props.data + '/' + this.props.data + this.props.imgNum + ".jpg"}
                />
            </div>
        );
    }
});


let ServicesWrapper = React.createClass({
    getInitialState: function() {
        return {
            'activeService': 'All Services'
        };
    },
    handleServiceTypeCtrlClick: function(serviceItem) {
        this.setState({
            'activeService': serviceItem
        });
    },
    render: function() {
        let ServiceInfo;
        let serviceTypeCtrlClickHandler = this.handleServiceTypeCtrlClick;
        let activeService = this.state.activeService;
        let ServiceTypeCtrls = serviceIntroData.map(function(data) {
            return (
                <ServiceTypeCtrl
                    key={data.id}
                    serviceName={data.serviceName}
                    serviceItem={data.serviceItem}
                    activeService={activeService}
                    onServiceTypeCtrlClick={serviceTypeCtrlClickHandler}
                />
            );
        });
        if (this.state.activeService == 'All Services') {
            ServiceInfo = serviceIntroData.map(function(data) {
                return (
                    <ServiceIntro key={data.id} data={data} onServiceIntroClick={serviceTypeCtrlClickHandler} />
                );
            });
        } else {
            ServiceInfo = <ServiceDetail data={serviceDetailData[this.state.activeService]} />
        }
        return (
            <div id="ServicesWrapper">
                <div className="jumbotron titleWithImageBackground-services" role="main">
                    <div className="container titleWithImageBackground-text">
                        <h1>{servicesData["title"]}</h1>
                        <p>{servicesData["shortIntro"]}</p>
                    </div>
                </div>
                <div className="container">
                    <div className="two-col-left col-md-3">
                        <ul className="list-group">
                            <ServiceTypeCtrl
                                serviceName={constData["allServices"]}
                                serviceItem={"All Services"}
                                activeService={activeService}
                                onServiceTypeCtrlClick={serviceTypeCtrlClickHandler}
                            />
                            {ServiceTypeCtrls}
                        </ul>
                    </div>
                    <div className="two-col-right col-md-9">
                        {ServiceInfo}
                    </div>
                </div>
            </div>
        );
    }
});


let ServiceIntro = React.createClass({
    rawMarkup: simpleMarkup,
    handleClick: function () {
        this.props.onServiceIntroClick(this.props.data.serviceItem);
    },
    render: function() {
        return (
            <div className="col-md-6 services-intro-block" onClick={this.handleClick}>
                <div className="services-intro-header">
                    <h3>
                        <a href="javascript:" dangerouslySetInnerHTML={this.rawMarkup(this.props.data.serviceName)} />
                    </h3>
                </div>
                <div className="services-intro-pic">
                    <img className="img-thumbnail center-block" src={this.props.data.imgPath} />
                </div>
                <div className="services-intro-text">
                    <p dangerouslySetInnerHTML={this.rawMarkup(this.props.data.introText + "<a class='services-intro-text-link' href='javascript:'>" + constData["readMore"] + "</a>")} />
                </div>
            </div>
        );
    }
});


let ServiceTypeCtrl = React.createClass({
    rawMarkup: simpleMarkup,
    handleClick: function() {
        this.props.onServiceTypeCtrlClick(this.props.serviceItem);
    },
    setClass: function() {
        if (this.props.activeService == this.props.serviceItem) {
            return "list-group-item active";
        } else {
            return "list-group-item";
        }
    },
    render: function() {
        return (
            <li className={this.setClass()} onClick={this.handleClick}>
                <a
                    href="javascript:"
                    dangerouslySetInnerHTML={this.rawMarkup(this.props.serviceName)}
                />
            </li>
        );
    }
});


let ServiceDetail = React.createClass({
    rawMarkup: simpleMarkup,
    render: function() {
        return (
            <div>
                <div className="row">
                    <div className="services-item-short-header"><h2 dangerouslySetInnerHTML={this.rawMarkup(this.props.data.serviceName)} /></div>
                    <hr className="featurette-divider" />
                    <div className="services-item-short col-md-7">
                        <div className="services-item-short-content" dangerouslySetInnerHTML={this.rawMarkup(this.props.data.shortContent)} />
                    </div>
                    <div className="col-md-5">
                        <img className="img-thumbnail center-block" src={this.props.data.imgPath} />
                    </div>
                </div>
                <div className="row1" dangerouslySetInnerHTML={this.rawMarkup(this.props.data.additionalContent)} />
            </div>
        );
    }
});


let AboutWrapper = React.createClass({
    rawMarkup: simpleMarkup,
    render: function() {
        return (
            <div id="AboutWrapper">
                <div className="jumbotron titleWithImageBackground-about" role="main">
                    <div className="container titleWithImageBackground-text" >
                        <h1>{aboutData["title"]}</h1>
                        <p>{aboutData["shortIntro"]}</p>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="services-item-short col-md-6">
                            <div className="services-item-short-header"><h2>{aboutData["section1"]["title"]}</h2></div>
                            <hr className="featurette-divider" />
                            <div className="services-item-short-content">
                                <p dangerouslySetInnerHTML={this.rawMarkup(aboutData["section1"]["content"])} />
                            </div>
                        </div>
                        <div className="col-md-6"><img className="img-thumbnail center-block" src={aboutData["imgPath"]} /></div>
                    </div>
                    <div className="row1 col-md-9">
                        <div>
                            <h4>{aboutData["section2"]["title"]}</h4>
                            <p>{aboutData["section2"]["content"]}</p>
                        </div>
                    </div>
                    <div className="row1 col-md-9">
                        <div>
                            <h4>{aboutData["section3"]["title"]}</h4>
                            <p>{aboutData["section3"]["content"]}</p>
                        </div>
                    </div>
                    <div className="row1 col-md-9">
                        <div>
                            <h4>{aboutData["section4"]["title"]}</h4>
                            <p>{aboutData["section4"]["content"]}</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
});


let ContactWrapper = React.createClass({
    componentDidMount: function() {
        const script1 = document.createElement("script");
        script1.id = "googleMapFunctionScript";
        script1.src = "/static/js/googleMapApi.js";
        document.getElementById("ContactWrapper").appendChild(script1);
        const script2 = document.createElement("script");
        script2.id = "googleMapApiScript";
        script2.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyAy7AbTkWyEiLZZv2Lsoi-wgydjGKv0NOI&callback=initMap";
        script2.async = true;
        script2.defer = true;
        document.getElementById("ContactWrapper").appendChild(script2);
    },
    // componentWillUnmount: function() {
    //     let s1 = document.getElementById("googleMapFunctionScript");
    //     s1.parentNode.removeChild(s1);
    //     let s2 = document.getElementById("googleMapApiScript");
    //     s2.parentNode.removeChild(s2);
    // },
    render: function() {
        return (
            <div id="ContactWrapper">
                <div id="map"></div>
                <div className="container">
                    <h1>{contactData["title"]}</h1>
                    <hr className="featurette-divider" />
                    <ContactInfo />
                    <ContactForm />
                </div>
            </div>
        );
    }
});


let ContactInfo = React.createClass({
    rawMarkup: simpleMarkup,
    render: function() {
        return (
            <div className="two-col-left col-md-4">
                <div dangerouslySetInnerHTML={this.rawMarkup(contactData["cell"])} />

                <hr className="featurette-divider" />
                <div dangerouslySetInnerHTML={this.rawMarkup(contactData["email"])} />
                <hr className="featurette-divider" />
                <div dangerouslySetInnerHTML={this.rawMarkup(contactData["hour"])} />
            </div>
        );
    }
});


let ContactForm = React.createClass({
    getInitialState: function() {
        return {
            'name': "",
            'email': "",
            'note': "",
        };
    },
    handleNameChange: function(e) {
        this.setState({
            'name': e.target.value,
        });
    },
    handleEmailChange: function(e) {
        this.setState({
            'email': e.target.value,
        });
    },
    handleNoteChange: function(e) {
        this.setState({
            'note': e.target.value,
        });
    },
    componentDidMount: function() {
        const script = document.createElement('script');
        script.id = "recaptchaScriptId";
        script.src = "https://www.google.com/recaptcha/api.js" + constData["form"]["langCode"];
        document.getElementById("ContactWrapper").appendChild(script);

        jQuery("#submitButton").on("click", function(e) {
            e.preventDefault();
            let grchRes = grecaptcha.getResponse();
            if (grchRes === "") {
                alert("Please check the reCAPTCHA box.");
            } else {
                if (this.state.name && this.state.email && this.state.note) {
                    jQuery.ajax({
                        type: "POST",
                        url: '/',
                        dataType: 'json',
                        data: {
                            "name": this.state.name,
                            "email": this.state.email,
                            "note": this.state.note,
                            "g-recaptcha-response": grchRes,
                        },
                        success: function(data) {
                            if (data && data.hasOwnProperty("status")) {
                                this.setState({
                                    'name': "",
                                    'email': "",
                                    'note': "",
                                });
                                grecaptcha.reset();
                                if (data.status == "success") {
                                    alert(constData["form"]["submitSuccessfully"]);
                                } else {
                                    alert(constData["form"]["submitFailed"]);
                                }
                            }
                        }.bind(this),
                        error: function(xhr, status, err) {
                            console.error('/', status, err.toString());
                        }
                    });
                } else {
                    alert(constData["form"]["fillFormAlert"]);
                }
            }
        }.bind(this));
    },
    render: function() {
        return (
            <div className="two-col-right col-md-8">
                <h3 style={{marginBottom: 20 + "px"}}>{contactData["formHeader"]}</h3>
                <div>
                    <form method="post" action="/">
                        <div className="fieldWrapper col-md-10 col-xs-12 pull-left">
                            <input
                                value={this.state.name}
                                onChange={this.handleNameChange}
                                className="contact-textInput form-control"
                                id="id_name"
                                name="name"
                                placeholder={constData["form"]["nameHolder"]}
                                type="text"
                            />
                        </div>
                        <div className="fieldWrapper col-md-10 col-xs-12 pull-left">
                            <input
                                value={this.state.email}
                                onChange={this.handleEmailChange}
                                className="contact-textInput form-control"
                                id="id_email"
                                name="email"
                                placeholder={constData["form"]["emailHolder"]}
                                type="text"
                            />
                        </div>
                        <div className="fieldWrapper col-md-10 col-xs-12 pull-left">
                            <textarea
                                value={this.state.note}
                                onChange={this.handleNoteChange}
                                className="contact-textInput form-control"
                                cols="40"
                                id="id_body"
                                name="body"
                                placeholder={constData["form"]["noteHolder"]}
                                rows="10"
                            />
                        </div>
                        <div id="recaptchaWrapper" className="g-recaptcha col-md-5 col-xs-12" data-sitekey="6LcVswkUAAAAAMJA0oHs9l4mNlhT3g-E35uJezAY" />
                        <div className="pull-left col-md-5 col-xs-12">
                            <button id="submitButton" className="btn pull-right" type="submit">{constData["form"]["submit"]}</button>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
});


let FooterWrapper = React.createClass({
    render: function() {
        return (
            <footer>
                <div className="container">
                    <hr className="featurette-divider" />
                    <p className="pull-right"><a href="#">{constData["backToTop"]}</a></p>
                    <p>
                        {constData["copyRightDeclare"]}
                        {/*· <a href="#">Privacy</a> · <a href="#">Terms</a>*/}
                    </p>
                </div>
            </footer>
        );
    }
});


function simpleMarkup(data) {
    return {__html: data};
}


function capFirst(s) {
    return s[0].toLocaleUpperCase() + s.substr(1);
}
